import React from "react"

import Layout from "../components/layout.js"
import { SEO } from "../components/seo.js"

export const Head = () => <SEO title="401: Not authorized" />

const NotAuthorizedPage = () => (
  <Layout>
    <h1>Whops</h1>
    <p>You are not authorized to see this page.</p>
  </Layout>
)

export default NotAuthorizedPage
